import React, { useState, useEffect, Fragment, createElement } from "react";
import Header from "./Header";
import URL from "../consts";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import {Beforeunload} from 'react-beforeunload';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { doc, setDoc, serverTimestamp } from "firebase/firestore"; 

const { v4: uuidv4 } = require('uuid');


let db = null;

function initFirestore() {
  const firebaseConfig = {
    apiKey: "AIzaSyBvsXDz3ArEqt8gijmzzAf6Xn7lZH17gcs",
    authDomain: "omilab-lau.firebaseapp.com",
    projectId: "omilab-lau",
    storageBucket: "omilab-lau.appspot.com",
    messagingSenderId: "179104642817",
    appId: "1:179104642817:web:708fb2f625e8890ac80d00",
    measurementId: "G-VJHQ3ZESGL"
  };
  const app = initializeApp(firebaseConfig);
  db =  getFirestore(app);
}


const useStyles = makeStyles((theme) => ({
  buttonLoader: {
    color: "#f7f7f7",
    marginTop: "5px",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let globalTimeLeft = 7 * 60;  // 7 minutes
setInterval(() => {
  globalTimeLeft -= 1;
  if (globalTimeLeft < 0) {
    globalTimeLeft = 0;
  }
}, 1000);

const Test = () => {
  const classes = useStyles();
  const [capture, setCapture] = useState([]);
  const [content, setContent] = useState("");
  const [user, setUser] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openProcess, setOpenProcess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [load, setLoad] = useState(false);
  const [direction, setDirection] = useState("rtl");
  const [timePassed, setTimePassed] = useState(false);
  const [buttonMessage, setButtonMessage] = useState('שליחה');
  initFirestore();

  useEffect(() => {
    const interval = setInterval(() => {
      if (globalTimeLeft > 0) {
        const minutes = Math.floor(globalTimeLeft / 60);
        const seconds = globalTimeLeft % 60;
        const secondsPadded = String(seconds).padStart(2, '0');
        setButtonMessage(`${minutes}:${secondsPadded}`);
        // console.debug('Setting btton message to ', `${minutes}:${secondsPadded}`);
      } else {
        setButtonMessage('שליחה');
        setTimePassed(true);
      }
    }, 100);
    return () => clearInterval(interval);
  });

  const checkValidation = () => {
    if (capture.length !== 0) handleClick(); // user !== "" && 
    else setOpenValidation(true);
  };

  async function sendToFirestore(user, direction, capture) {
    console.log('Saving results in Firestore');
    const captureId = createCaptureId();
    const userRef = doc(db, 'input', user);
    const userDoc = {
      userId: user,
      timestamp: serverTimestamp(),
      direction
    }
    console.debug('Updating user doc ', userDoc);
    await setDoc(userRef, userDoc);

    const captureRef = doc(userRef, 'captures', captureId);
    const captureDoc = {
      captureId,
      timestamp: serverTimestamp(),
      direction,
    };

    console.debug('Writing capture doc', captureDoc);
    await setDoc(captureRef, captureDoc);

    const task = 'free text 7';
    const taskRef = doc(captureRef, 'tasks', task);
    const taskDoc = {
      captureId,
      timestamp: serverTimestamp(),
      task,
    };
    await setDoc(taskRef, taskDoc);

    const MAX_DOC_SIZE = 485000;  // The actual maximum is 1,048,576, but Hebrew letters take two bytes, and we also want a little bit of a buffer
    const promises = [];

    let blockNum = 0;
    let blockStartIndex = 0;
    let block = [];

    function saveBlock() {
      const eventDoc = {
        blockNum,
        startIndex: blockStartIndex,
        data: block
      };
      const eventRef = doc(taskRef, 'events', blockNum.toString());
      console.debug('Saving block ', blockNum, ' from ', blockStartIndex, ' length ', JSON.stringify(eventDoc).length, eventDoc);
      const promise = setDoc(eventRef, eventDoc);
      promises.push(promise);
    }

    for(let i =0; i<capture.length; i++) {
      if (JSON.stringify(block).length > MAX_DOC_SIZE) {
        saveBlock();
        blockNum += 1;
        blockStartIndex = i;
        block = [];
      }
      block.push(capture[i]);
    }
    if (block.length) {
      saveBlock();
    }

    console.debug('Waiting for all save operations to finish');
    await Promise.all(promises);
    console.log('Storing result in firestore - done');
  }

   const handleClick = async () => {
    try {
      setLoad(true);
      await sendToFirestore('0', direction, capture); // now user='0'
      setLoad(false);
      // Redirect to the next survey. Use replace so users can go back, which can cause many issues with the survey (reposting, etc...)
      // window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSewcCqxDahC1B_lTaGbCrWn7YRy-Ux9xnzX5WPeYRpP9l0gWA/viewform'); 
      window.location.replace('/thank-you');
    } catch (err) {
        console.error("Can't store in Firestore: ", err);
        alert('תקלה - לא הצלחנו לשמור את תוצאות הניסוי\n' + err)
    }

/*
    setLoad(true);
    let url = "https://word-save.herokuapp.com/" + "save";
    console.log({ capture: capture });
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        user: user,
        direction: direction,
        capture: capture,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser("");
        setContent("");
        setCapture([]);
        setLoad(false);
        setOpenSuccess(true);
      })
      .catch((err) => {
        setLoad(false);
        setOpenError(true);
      });*/
  };

  const createCaptureId = () => {
    return uuidv4();
  };

  return (
    <div className="main">
      {/* <Header /> */}
      <p style={{width: "90%", display: "flex", justifyContent: "flex-end", textAlign:"right", direction:"rtl"}}>
          הקלידו במשך 7 דקות תיאור של חופשה שלקחתם בשנה-שנתיים האחרונות או של
          בילוי (מסיבה, טיול, ארוע תרבותי, ארוע משפחתי) שבו השתתפתם. אנא תארו את
          הארוע בפרוטרוט, והקפידו על כתיבה ברורה וקלה לקריאה.
        </p>
      <div
        style={{ width: "90%", display: "flex", justifyContent: "flex-end" }}
      >
        
        {/* <TextField
          id="standard-basic"
          // label="Name"
          label="שם/ מספר משתתף"
          onChange={(event) => setUser(event.target.value)}
          value={user}
          style={{ marginBottom: "20px", direction: "rtl" }}
        /> */}
      </div>
      {/* <div
        style={{
          display: "flex",
          marginBottom: "10px",
          border: "1px solid Gainsboro",
          borderRadius: "5px",
          padding: "5px",
          width: "60px",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            cursor: "pointer",
            backgroundColor: direction === "ltr" ? "Gainsboro" : "",
          }}
          onClick={() => setDirection("ltr")}
        >
          <FormatAlignLeftIcon />
        </span>
        <span
          style={{
            cursor: "pointer",
            backgroundColor: direction === "rtl" ? "Gainsboro" : "",
          }}
          onClick={() => setDirection("rtl")}
        >
          <FormatAlignRightIcon />
        </span>
      </div> */}
      <textarea
        className="form-control"
        id="leaders"
        rows="10"
        style={{ width: "90%", direction: direction }}
        onKeyDown={(event) => {
          setCapture([
            ...capture,
            {
              key: event.key,
              timestamp: event.timeStamp,
              type: "KeyDown",
              content: content,
            },
          ]);
        }}
        onKeyUp={(event) => {
          setCapture([
            ...capture,
            {
              key: event.key,
              timestamp: event.timeStamp,
              type: "KeyUp",
              content: content,
            },
          ]);
        }}
        onMouseDown={(event) => {
          setCapture([
            ...capture,
            {
              key: "mouse",
              timestamp: event.timeStamp,
              type: "KeyDown",
              content: content,
            },
          ]);
        }}
        onMouseUp={(event) => {
          setCapture([
            ...capture,
            {
              key: "mouse",
              timestamp: event.timeStamp,
              type: "KeyUp",
              content: content,
            },
          ]);
        }}
        onChange={(event) => setContent(event.target.value)}
        value={content}
      />
      <br />
      {/* <Button
        style={{
          backgroundColor: "#A8B7AB",
          width: "100px",
          marginRight: "15px",
        }}
        variant="contained"
        onClick={() => {
          setContent("");
          setUser("");
          setCapture([]);
        }}
      >
        Clear
      </Button> */}
      <Button
        style={{
          backgroundColor: "#A8B7AB",
          width: "100px",
          marginRight: "15px",
        }}
        variant="contained"
        onClick={checkValidation}
        disabled={!timePassed || capture.length === 0}
      >
        {load ? (
          <CircularProgress size={20} className={classes.buttonLoader} />
        ) : (
          // "Send"
          buttonMessage
        )}
      </Button>
      { load? (<span dir="rtl">שמירת הנתונים עשויה לקחת מספר דקות...</span>) : '' }
      { load? <Beforeunload onBeforeunload={() => 'נא להמתין לסיום שמירת הנתונים'} /> : '' }
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
      >
        <Alert onClose={() => setOpenSuccess(false)} severity="success">
          Test saved!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openProcess}
        autoHideDuration={6000}
        onClose={() => setOpenProcess(false)}
      >
        <Alert onClose={() => setOpenProcess(false)} severity="success">
          Test is being recorded, please wait!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openError}
        autoHideDuration={6000}
        onClose={() => setOpenError(false)}
      >
        <Alert onClose={() => setOpenError(false)} severity="error">
          Something went wrong..
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openValidation}
        autoHideDuration={6000}
        onClose={() => setOpenValidation(false)}
      >
        <Alert onClose={() => setOpenValidation(false)} severity="error">
          Name or text field can't be empty
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Test;
