import React from "react";
import Chart from "react-google-charts";

function EditingEvents(props) {
  function deleteBinsData(props) {
    let deleteBinsData = [];
    deleteBinsData.push(["Declie", "Num of words erased"]);
    for (let i = 0; i < 10; i++)
      deleteBinsData.push([
        props.result.binning_time[i],
        props.result.binning_words_erased[i],
      ]);
    return deleteBinsData;
  }
  function modifiedBinsData(props) {
    let modifiedBinsData = [];
    modifiedBinsData.push(["Declie", "Num of words modified"]);
    for (let i = 0; i < 10; i++)
      modifiedBinsData.push([
        props.result.binning_time[i],
        props.result.binning_words_modified[i],
      ]);
    return modifiedBinsData;
  }
  return (
    <div className="card" style={{ marginTop: "10px" }}>
      <div className="card-body">
        <h5 className="card-title">Editing events</h5>
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%" }}>
            <p>words erased {props.result.words_erased}</p>
            <p>words modified {props.result.words_modified}</p>
          </div>
          <Chart
            style={{ marginLeft: "20px" }}
            width={"500px"}
            height={"300px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={deleteBinsData(props)}
            options={{
              // Material design options
              chart: {
                title: "Number of words erased by temporal decile",
                subtitle: `Decile - ${
                  props.result.binning_time.slice(-1)[0]
                } seconds`,
              },
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
          <Chart
            style={{ marginLeft: "20px" }}
            width={"500px"}
            height={"300px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={modifiedBinsData(props)}
            options={{
              // Material design options
              chart: {
                title: "Number of words modified by temporal decile",
                subtitle: `Decile - ${
                  props.result.binning_time.slice(-1)[0]
                } seconds`,
              },
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      </div>
    </div>
  );
}

export default EditingEvents;
