import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

function Keyboard(props) {
  const [isOnline, setIsOnline] = useState(null);
  function keyStrokeBinsData(props) {
    let keyStrokeBinsData = [];
    keyStrokeBinsData.push(["Declie", "Num of keystrokes"]);
    for (let i = 0; i < 10; i++)
      keyStrokeBinsData.push([
        props.result?.binning_time[i],
        props.result?.keystrokes_bins[i],
      ]);
    return keyStrokeBinsData;
  }
  function wordsBinsData(props) {
    let wordsBinsData = [];
    wordsBinsData.push(["Declie", "Num of words"]);
    for (let i = 0; i < 10; i++)
      wordsBinsData.push([
        props.result?.binning_time[i],
        props.result?.binning_words[i],
      ]);
    return wordsBinsData;
  }
  function sentencesBinsData(props) {
    let sentencesBinsData = [];
    sentencesBinsData.push(["Declie", "Num of sentences"]);
    for (let i = 0; i < 10; i++)
      sentencesBinsData.push([
        props.result?.binning_time[i],
        props.result?.binning_sentences[i],
      ]);
    return sentencesBinsData;
  }
  // function CharactersBins(props) {
  //   let charsBinsData = []
  //   charsBinsData.push(['Bin', 'Num of chars'])
  //   for(let i=0;i<10;i++)
  //       charsBinsData.push([i, props.result.chars_bins[i]])
  //   return charsBinsData
  // }
  useEffect(() => {
  }, [props.result]);
  //     ChatAPI.subscribeToFriendStatus(props.friend.id, handleStatusChange);
  return (
    <div className="card" style={{ marginTop: "10px" }}>
      <div className="card-body">
        <h5 className="card-title">Keyboard events</h5>
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%" }}>
            {/* <p>
              num of keyboard strokes per minute{" "}
              {props.result?.keystrokes_per_minute}
            </p> */}

            <p>num of keyboard strokes per minute {props.result?.keystrokes_per_minute?.toFixed(2)}</p>
          </div>
          {/* <p>num of characters per minute {props.result.chars_per_minute}</p> */}
          <Chart
            style={{ marginLeft: "20px" }}
            width={"500px"}
            height={"300px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={keyStrokeBinsData(props)}
            options={{
              // Material design options
              chart: {
                title: "Number of keystrokes by temporal decile",
                subtitle: `Decile - ${
                  props.result.binning_time.slice(-1)[0]
                } seconds`,
              },
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
          <Chart
            style={{ marginLeft: "20px" }}
            width={"500px"}
            height={"300px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={wordsBinsData(props)}
            options={{
              // Material design options
              chart: {
                title: "Number of words by temporal decile",
                subtitle: `Decile - ${
                  props.result.binning_time.slice(-1)[0]
                } seconds`,
              },
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
        <div style={{ width: "30%" }}>
          <Chart
            style={{ marginLeft: "20px" }}
            width={"500px"}
            height={"300px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={props.result?.binning_sentences? sentencesBinsData(props):null}
            options={{
              // Material design options
              chart: {
                title: "Number of sentences by temporal decile",
                subtitle: `Decile - ${
                  props.result.binning_time.slice(-1)[0]
                } seconds`,
              },
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Keyboard;
