import React from 'react'
import {Route} from 'react-router-dom'
import Main from './components/main'
import Login from './components/Login'
import Test from './components/test'
import Results from './components/results'
import Header from './components/Header'
import StartPage from './components/start';
import ThankYou from './components/thankyou'
// import Narrative from './components/Narrative'
// const path = window.location.pathname

const ReactRouter = () => {
    return(
        <React.Fragment>
            {/* <Route exact path="/" component={Login}/> */}
            <Route exact path="/" component={StartPage}/>
            <Route exact path="/experiment" component={Test}/>
            <Route exact path="/thank-you" component={ThankYou}/>
            { /*<Route exact path="/results" component={Results}/> */ }
        </React.Fragment>
    )
}

export default ReactRouter