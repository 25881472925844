import React from "react";
import Chart from "react-google-charts";

function Pauses(props) {
  function keyStrokeBinsPauseData(props) {
    let keyStrokeBinsPauseData = [];
    keyStrokeBinsPauseData.push([
      "Decile",
      "Total time",
    ]);
    for (let i = 0; i < 10; i++)
      keyStrokeBinsPauseData.push([
        props.result.binning_time[i],
        props.result.binning_pauses_before_keystrokes[i],
      ]);
    return keyStrokeBinsPauseData;
  }
  function wordsStrokeBinsPauseData(props) {
    let wordsStrokeBinsPauseData = [];
    wordsStrokeBinsPauseData.push(["Declie", "Total time"]);
    for (let i = 0; i < 10; i++)
      wordsStrokeBinsPauseData.push([
        props.result.binning_time[i],
        props.result.binning_pauses_before_wordss[i],
      ]);
    return wordsStrokeBinsPauseData;
  }

  return (
    <div className="card" style={{ marginTop: "10px" }}>
      <div className="card-body">
        <h5 className="card-title">Pauses</h5>
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%" }}>
            <p>
              avg pauses before keystrokes{" "}
              {props.result.avg_pauses_before_keystrokes} sec
            </p>
            <p>
              avg pauses before words (intra-sentence, continuous){" "}
              {props.result.avg_pauses_before_words} sec
            </p>
          </div>
          <Chart
            style={{ marginLeft: "20px" }}
            width={"500px"}
            height={"300px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={keyStrokeBinsPauseData(props)}
            options={{
              // Material design options
              chart: {
                title: "Total pauses before keystrokes by temporal decile",
                subtitle: `Decile - ${
                  props.result.binning_time.slice(-1)[0]
                } seconds`,
              },
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
          <Chart
            style={{ marginLeft: "20px" }}
            width={"500px"}
            height={"300px"}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={wordsStrokeBinsPauseData(props)}
            options={{
              // Material design options
              chart: {
                title: "Total pauses before words by temporal decile",
                subtitle: `Decile - ${
                  props.result.binning_time.slice(-1)[0]
                } seconds`,
              },
              legend: { position: "none" },
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Pauses;
