import React, { Component } from "react";

class EndPage extends Component {
    render() {
        return (
            <div dir="rtl" style={{margin: "30px", width: "90%"}}>

                <p style={{justifyContent: "center", textAlign:"right", fontSize:"large", lineHeight: "220%"}}>
                    תודה רבה על השתתפותך!
                </p>
                <p style={{justifyContent: "center", textAlign:"right", fontSize:"large", lineHeight: "180%"}}>
                    ניתן לעבור <a href="https://docs.google.com/forms/d/e/1FAIpQLSd72Q7vYyOKGEmPot4-UsnHDJt3dq3g1YRT51x7-L2VX7Z4_g/viewform?usp=sf_link
">לטופס הסיום</a>.</p>

            </div>
        )};
    
};

export default EndPage;
