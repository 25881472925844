import React, { Component } from "react";
import Button from "@material-ui/core/Button";

class StartPage extends Component {
    constructor(props) {
        super(props);
    }

    startExperiment() {
        window.location.replace('/experiment');
    }

    render() {
        return (
            <div dir="rtl" style={{margin: "30px", width: "90%"}}>

                <p style={{justifyContent: "flex-end", textAlign:"right", fontSize:"large", lineHeight: "220%"}}>
                    שלום, שמי פרופ' יורם קלמן ואני חבר סגל במחלקהֹֹֹֹ לניהול ולכלכלה באוניברסיטה הפתוחה. המחקר אליו תגיעו בעמוד הבא עוסק בניתוח האופן שבו אנשים שונים מקלידים טקסט במחשב.
                    <br></br>
                    אודה לכם אם תבצעו את מטלת ההקלדה שמפורטת בעמוד הבא, ותקלידו את הטקסט שהתבקשתם בתיבה שמופיעה מתחת להנחיות. כפי שתראו, אתם מתבקשים להקליד במשך שבע דקות. הוספנו בעמוד טיימר שמראה לכם כמה זמן נותר לכם. הטיימר לא משפיע על הניסוי, אך אמור לסייע לכם להמשיך לכתוב במשך הזמן שהוקצב לכם, כדי שנקבל דגימה טובה של הכתיבה שלכם.
                    <br></br>
                    אם המטלה מעוררת בכם אי נוחות, אתם רשאים לא לבצע אותה או להפסיק את ביצועה בכל שלב, מבלי שלדבר תהיינה השלכות שליליות כלשהן.
                    <br></br>
                    השאלון הנו אנונימי והמידע ישמש לצרכי מחקר בלבד.
                    <br></br>
                    השאלון מיועד לבני 18 ומעלה בלבד.
                    <br></br><br></br>
                    מילוי המטלה והגשתה מהווים הסכמה להשתתף במחקר, זאת מבלי לגרוע מזכותכם להפסיק את השתתפותכם בכל עת.
                    <br></br><br></br>
                    תודה רבה עבור שיתוף הפעולה!
                    <br></br>
                    לשאלות אודות המחקר ניתן לפנות לכתובת המייל yoramka@openu.ac.il
                </p>
                <Button 
                    style={{
                        backgroundColor: "#A8B7AB",
                        width: "100px",
                        marginRight: "15px",
                        }}
                    onClick={this.startExperiment}>התחלה
                </Button>

            </div>
        )};
    
};

export default StartPage;
