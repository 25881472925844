import React from 'react';

function Characteristic(props) {
    return (
      <div className="card" style={{marginTop:"10px"}}>
        <div className="card-body">
          <h5 className="card-title">Final text characteristic</h5>
          <p>Number of words in final text {props.result.num_of_words}</p>
          <p>Number of words typed {props.result.total_num_of_words}</p>
          <p>Number of characters in final text {props.result.num_of_chars}</p>
          <p>Number of characters typed {props.result.total_num_of_chars}</p>
          <p>Number of sentences in final text {props.result.total_num_of_sentences}</p>
          <p>Number of sentences typed {props.result.num_of_sentences}</p>
          <p>Total typing time {props.result.total_typing_time} sec</p>
          <p>Number of words erased {props.result.words_erased}</p>     
          <p>Number of words modified </p>     
      </div>  
   </div>      
    )
  }

export default Characteristic;