import React, { Component, Fragment } from "react";
import Chart from "react-google-charts";
import Header from "./Header"
import Characteristic from "./Characteristic";
import Keyboard from "./Keyboard";
import EditingEvents from "./EditingEvents";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Pauses from "./Pauses";
import URL from "../consts";
import TableViewer from "react-js-table-with-csv-dl";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tests: [],
      resFlag: false,
      result: null,
      chosen: "",
      headers: ["key", "timestamp", "content", "changes"],
      table: [],
      activateDownloadButton: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let url = URL + "getTests";
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ tests: data.res });
      })
      .catch((err) => console.log(err));
  }

  handleChange(event) {
    const item = event.target.value;
    // const found = this.state.tests.find((element) => element._id === item);
    // console.log(found)
    const url = URL + "analyze";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        captureId: item,
        // user: "random",
        // capture: found.capture,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.cahnges_csvx);
        const found = this.state.tests.find((element) => element._id === item);
        this.setState({
          result: data.res,
          resFlag: true,
          chosen: found.user,
          table: data.res.cahnges_csv,
        });
      })
      .catch((err) => 
      this.setState({
        result: null,
      }));
  }

  render() {
    return (
      <div className="main">
        <Header/>
        {this.state.tests.length !== 0 ? (
          <FormControl>
            <InputLabel id="demo-simple-select-label">Tests</InputLabel>
            <Select onChange={this.handleChange} style={{ width: "150px" }}>
              {this.state.tests.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.user}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          ""
        )}
        {this.state.resFlag ? (
          <div>
            <Fragment>
              <div className="card" style={{ marginTop: "10px" }}>
                <div className="card-body">
                  <h5 className="card-title">{this.state.chosen}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">Capture</h6>
                  <p className="card-text" style={{ whiteSpace: "pre-line" }}>
                    {this.state.result.capture}
                  </p>
                </div>
              </div>
              {(this.state.result?.total_num_of_chars==="" || !this.state.result) && 
              <p>Failed analyze</p>
              }
              {this.state.result && (
                <Characteristic result={this.state.result} />
              )}
              {this.state.result && <Keyboard result={this.state.result} />}
              {this.state.result && (
                <EditingEvents result={this.state.result} />
              )}
              {this.state.result && <Pauses result={this.state.result} />}

              <TableViewer
                headerCss={{ backgroundColor: "#A8B7AB" }}
                downloadButtonStyle={{ color: "#A8B7AB" }}
                title=""
                content={this.state.table}
                headers={this.state.headers}
                minHeight={0}
                maxHeight={400}
                pagination={20}
                activateDownloadButton={this.state.activateDownloadButton}
              />
              <div className="card" style={{ marginTop: "10px" }}>
                <div className="card-body">
                  {this.state.result?.changes.map((change) => {
                    return (
                      <p style={{ whiteSpace: "pre-line" }}>
                        {change}
                        <br />
                      </p>
                    );
                  })}
                </div>
              </div>
            </Fragment>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default Results;
